import React from "react"
import { Link, graphql } from "gatsby"
import Header from "../components/Header/header"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import CtaBanner from "../components/CtaBanner/CtaBanner"

import "../assets/sass/theme/pages/services.scss"

const ServicesPage = ({ data }) => {
  const { edges: serviceEngineering } = data.serviceEngineering
  const { edges: servicePrecision } = data.servicePrecision
  return (
    <Layout>
      <Header isHeaderSolid />
      <SEO
        title="Our Services"
        description="CNR Engineering specialise in engineering, fabrication, precision CNC engineering, machinery installation and removals."
      />
      <main className="services with-spacing">
        <div className="page-width">
          <div className="services__inner">
            <div className="service__inner_left">
              <h1 className="title">Our Services</h1>
              <h4>CNR Engineering</h4>
              <ul>
                {serviceEngineering &&
                  serviceEngineering.map(({ node: service }) => (
                    service.frontmatter.displayonsite &&
                    service.frontmatter.title === "Marked Structural Steel"
                    ?
                    <li key={service.id}>
                      <Link to={service.fields.slug} style={{position: "relative", paddingLeft: "30px"}}>
                        <svg style={{left: 0, position: "absolute", top: "-4px"}} xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 280 200" fillRule="evenodd" clipRule="evenodd"><path d="M110 199.5a100 100 0 01-10 .5 100 100 0 010-200 100 100 0 0110 .5v30a70 70 0 00-10-.5 70 70 0 000 140 70 70 0 0010-.5zM280 199.5a100 100 0 01-10 .5 100 100 0 010-200 100 100 0 0110 .5v30a70 70 0 00-10-.5 70 70 0 00-68.38 55H260v30h-58.38A70 70 0 00270 170a70 70 0 0010-.5z"/></svg>
                        {service.frontmatter.description}
                      </Link>
                    </li>
                    :
                    service.frontmatter.displayonsite &&
                    <li key={service.id}>
                      <Link to={service.fields.slug}>
                        {service.frontmatter.description}
                      </Link>
                    </li>
                  ))
                }
              </ul>
              <h4>CNR Precision</h4>
              <ul>
                {servicePrecision &&
                  servicePrecision.map(({ node: service }) => (
                    service.frontmatter.displayonsite &&
                    service.frontmatter.title === "Marked Structural Steel"
                    ?
                    <li key={service.id}>
                      <Link to={service.fields.slug} style={{position: "relative", paddingLeft: "30px"}}>
                        <svg style={{left: 0, position: "absolute", top: "-4px"}} xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 280 200" fillRule="evenodd" clipRule="evenodd"><path d="M110 199.5a100 100 0 01-10 .5 100 100 0 010-200 100 100 0 0110 .5v30a70 70 0 00-10-.5 70 70 0 000 140 70 70 0 0010-.5zM280 199.5a100 100 0 01-10 .5 100 100 0 010-200 100 100 0 0110 .5v30a70 70 0 00-10-.5 70 70 0 00-68.38 55H260v30h-58.38A70 70 0 00270 170a70 70 0 0010-.5z"/></svg>
                        {service.frontmatter.description}
                      </Link>
                    </li>
                    :
                    service.frontmatter.displayonsite &&
                    <li key={service.id}>
                      <Link to={service.fields.slug}>
                        {service.frontmatter.description}
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </div>
            <div></div>
            <div className="service__inner_right" style={{backgroundImage: "url('/static/loading-82facc05a59632b21fee8af747679af7.jpg')", backgroundPosition: "center center"}}></div>
          </div>
        </div>
      </main>
      <CtaBanner />
    </Layout>
  )
}

export default ServicesPage

export const query = graphql`
  query ServicesPageQuery {
    serviceEngineering: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "service-child-page"}, servicetype: {eq: "CNR Engineering"}}}, sort: {fields: frontmatter___sortorder}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            templateKey
            displayonsite
          }
        }
      }
    }
    servicePrecision: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "service-child-page"}, servicetype: {eq: "CNR Precision"}}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            templateKey
            displayonsite
          }
        }
      }
    }
  }
`
